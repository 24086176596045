import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: {
    open: false,
    info: "message sent successfully",
  },
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    enableNotification: (state, dispatch) => {
      state.notification.open = true;
    },
    disableNotification: (state, dispatch) => {
      state.notification.open = false;
    },
  },
});

export const { enableNotification, disableNotification } = appSlice.actions;
export default appSlice.reducer;
