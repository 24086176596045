import React from "react";
import { Outlet } from "react-router-dom";
import Notification from "../components/notification/Notification";

const AppWrapper = () => {
  return (
    <div className="main_container">
      {/* <Navbar navItems={navItems} /> */}
      <Outlet />
      <Notification />
    </div>
  );
};

export default AppWrapper;
