import React from "react";
import MotionWrap from "../../wrapper/MotionWrapper";

const WhyChooseUs = () => {
  return (
    <div id="why choose us" className="px-[2.5rem] md:px-[5rem] pb-10">
      <div className="text-center text-2xl font_bold py-14">Why choose us?</div>
      {/* why choose us options */}
      <div className="flex flex-wrap justify-evenly gap-4">
        <div className="flex flex-col items-center">
          <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598937/liveinnstays/general/money.png"
              }
              alt="money"
              className="h-14 w-14"
            />
          </div>
          <div className="text-sm font_semibold">Budget-Friendly</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598933/liveinnstays/general/distance.png"
              }
              alt="prime location"
              className="h-14 w-14"
            />
          </div>
          <div className="text-sm font_semibold">Prime Locations</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598933/liveinnstays/general/guidance.png"
              }
              alt="local guidance"
              className="h-14 w-14"
            />
          </div>
          <div className="text-sm font_semibold">Local Guidance</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598940/liveinnstays/general/satisfaction.png"
              }
              alt="guest satisfaction"
              className="h-14 w-14"
            />
          </div>
          <div className="text-sm font_semibold">Guest Satisfaction</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598942/liveinnstays/general/team.png"
              }
              alt="friendly staff"
              className="h-14 w-14"
            />
          </div>
          <div className="text-sm font_semibold">Friendly Staff</div>
        </div>
      </div>
    </div>
  );
};

export default MotionWrap(WhyChooseUs, "app__about");
