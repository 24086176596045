import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const Navbar = ({ navItems }) => {
  const navigate = useNavigate();
  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);

  const scrollToView = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    setOpenMobileNavbar(false);
  };

  const toggleNavbar = () => {
    setOpenMobileNavbar(!openMobileNavbar);
  };

  return (
    <div className=" w-full h-12 fixed z-10 bg-white shadow-2xl">
      <div className="common_container_styling h-full flex justify-between items-center ">
        <div className="font_bold cursor-pointer" onClick={() => navigate("/")}>
          LiveInn Stays
        </div>
        <ul className="hidden sm:flex gap-5">
          {navItems.map((item) => (
            <li
              key={item}
              onClick={() => scrollToView(item)}
              className="capitalize font_medium cursor-pointer"
            >
              {item}
            </li>
          ))}
        </ul>
        <GiHamburgerMenu
          className="sm:hidden w-8 h-8 cursor-pointer"
          onClick={toggleNavbar}
        />
      </div>
      <div
        className={`common_container_styling sm:hidden relative ${
          openMobileNavbar ? "h-40" : "h-0"
        } overflow-hidden bg-white z-10 transition-all duration-500`}
      >
        <ul>
          {navItems.map((item) => (
            <li
              key={item}
              onClick={() => scrollToView(item)}
              className="py-2 capitalize font_medium cursor-pointer text-right"
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
