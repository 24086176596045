import React, { useEffect, useRef, useState } from "react";

const Accordian = ({ slides }) => {
  const intervalRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex !== 3) return prevIndex + 1;
        else return 0;
      });
    }, 4000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="w-full h-full bg-contain duration-500 relative">
      {slides.map((slide, i) => (
        <img
          key={i}
          src={slide}
          alt={slide}
          className={`${
            i === currentIndex ? "opacity-100" : "opacity-0"
          } object-cover h-full w-full absolute bg-center bg-cover duration-[1500ms]`}
        />
      ))}
    </div>
  );
};

export default Accordian;
