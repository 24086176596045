import React, { useEffect, useRef, useState } from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import "./Gallery.css";
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = () => {
  const galleryRef = useRef(null);
  const images = useSelector((state) => state.hotelInfoSlice.hotelInfo.images);
  const [slideShow, setSlideShow] = useState([]);
  const [updatedGallery, setUpdatedGallery] = useState([]);

  const splitImageArray = (slides) => {
    console.log(slides);
    let array = [...slides];
    var results = [];
    while (array.length) {
      results.push(array.splice(0, 3));
    }
    console.log(results);
    setSlideShow(results);
  };

  const openSlideShow = (id) => {
    galleryRef.current.slideToIndex(id);
    galleryRef.current.toggleFullScreen();
  };

  useEffect(() => {
    const data = images.map((img, index) => {
      return {
        id: index,
        original: img,
        thumbnail: img,
      };
    });
    setUpdatedGallery(data);
  }, [images]);

  useEffect(() => {
    splitImageArray(updatedGallery);
  }, [updatedGallery]);

  return (
    <div id="gallery" className="px-[1rem] md:px-[5rem]">
      <div className="text-center text-2xl font_bold py-14">Gallery</div>
      <div className="flex flex-wrap">
        {slideShow.map((slides, i) => (
          <div key={i} className="flex w-full md:w-1/2 flex-wrap">
            <div className="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center cursor-pointer"
                onClick={() => openSlideShow(slides[1].id)}
                src={slides[1].original}
              />
            </div>
            <div className="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center cursor-pointer"
                onClick={() => openSlideShow(slides[2].id)}
                src={slides[2].original}
              />
            </div>
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center cursor-pointer"
                onClick={() => openSlideShow(slides[0].id)}
                src={slides[0].original}
              />
            </div>
          </div>
        ))}
      </div>
      <ImageGallery
        ref={galleryRef}
        items={updatedGallery}
        showThumbnails={false}
        showNav
        showBullets
        showIndex
        autoPlay={false}
        useBrowserFullscreen={false}
      />
    </div>
  );
};

export default MotionWrap(Gallery, "app__about");
