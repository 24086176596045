import React from "react";
import { TypeAnimation } from "react-type-animation";
import Accordian from "../../components/accordian/Accordian";
import PropertyOverview from "../../components/property-overview/PropertyOverview";
import WhyChooseUs from "../../components/why-choose-us/WhyChooseUs";
import ContactUs from "../../components/contact-us/ContactUs";
import Footer from "../../components/footer/Footer";
import Map from "../../components/map/Map";

const slides = [
  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1703873761/liveinnstays/home/home1.jpg",
  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1703873761/liveinnstays/home/home2.jpg",
  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1703945115/liveinnstays/home/home3.jpg",
  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1703945118/liveinnstays/home/home4.png",
];

const Home = () => {
  return (
    <div className="w-full m-auto relative h-1/2 md:h-[60%] lg:h-[80%]">
      <div className="w-full m-auto relative group flex flex-col h-full">
        <Accordian slides={slides} />
      </div>
      <div className="absolute flex flex-col justify-center items-center text-white bg-gradient-to-r from-black top-0 w-full h-full">
        <div className="text-2xl md:text-3xl pt-2 text-left md:text-center px-2">
          <TypeAnimation
            style={{
              whiteSpace: "pre-line",
            }}
            sequence={[
              `Welcome to LiveInnStays\n Stay with us without breaking the bank.`,
              1000,
            ]}
            repeat={Infinity}
          />
        </div>
      </div>
      <PropertyOverview />
      <WhyChooseUs />
      <Map />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
