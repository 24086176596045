import React from "react";
import { IoMdTrain, IoMdAirplane } from "react-icons/io";
import { BiBus } from "react-icons/bi";

const HowToReach = () => {
  return (
    <div id="travel" className="px-[2.5rem] md:px-[5rem] pb-14">
      <div className="text-center text-2xl font_bold py-14">How To Reach</div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-5">
        <div className="h-64 flex-1 p-2 rounded-xl shadow-custom text-center">
          <IoMdAirplane size={40} className="pb-2 m-auto" />
          <p>
            The nearest airport is Dehradun's Jolly Grant Airport, which is
            approximately 54 kilometres away and has regular flights to New
            Delhi.
          </p>
        </div>
        <div className="h-64 flex-1 p-2 rounded-xl shadow-custom text-center">
          <IoMdTrain size={40} className="pb-2 m-auto" />
          <p>
            The closest train station to Mussoorie is in Dehradun, which enjoys
            good train connectivity with various popular cities of Northern
            India, be it Delhi, Chandigarh, or Jaipur.
          </p>
        </div>
        <div className="h-64 flex-1 p-2 rounded-xl shadow-custom text-center">
          <BiBus size={40} className="pb-2 m-auto" />
          <p>
            Mussoorie, as a popular tourist destination, is well-connected by a
            network of bus services that includes both private tour operators
            and state transportation corporations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToReach;
