import React, { useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import Accordian from "../../components/accordian/Accordian";
import Navbar from "../../components/navbar/Navbar";
import Gallery from "../../components/gallery/Gallery";
import AboutUs from "../../components/about-us/AboutUs";
import Location from "../../components/location/Location";
import Footer from "../../components/footer/Footer";
import Aminities from "../../components/aminities/Aminities";
import { useDispatch, useSelector } from "react-redux";
import { addInfo, removeInfo } from "../../store/slices/hotelInfoSlice";
import { allMussoorieHotels } from "../../utils/constants";
import { useParams } from "react-router-dom";

const navItems = ["about us", "aminities", "gallery", "location"];

const HotelInfo = () => {
  const { hotel } = useParams();
  const dispatch = useDispatch();
  const hotelInfo = useSelector((state) => state.hotelInfoSlice.hotelInfo);

  useEffect(() => {
    dispatch(addInfo(allMussoorieHotels[hotel]));

    return () => {
      console.log("triggered");
      dispatch(removeInfo());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Object.keys(hotelInfo).length !== 0 ? (
    <div className="main_container">
      <Navbar navItems={navItems} />
      <div className="w-full m-auto relative h-1/2 md:h-[60%] lg:h-[80%]">
        <div className="w-full m-auto relative group flex flex-col h-full">
          <Accordian slides={hotelInfo.images.slice(0, 5)} />
        </div>
        <div className="absolute flex flex-col justify-center items-center text-white bg-gradient-to-r from-black top-0 w-full h-full">
          <div className="text-2xl md:text-3xl pt-2 text-left md:text-center px-2">
            <TypeAnimation
              style={{
                whiteSpace: "pre-line",
              }}
              sequence={[hotelInfo.name, 1000]}
              repeat={Infinity}
            />
          </div>
        </div>
      </div>
      <AboutUs />
      <Aminities />
      <Gallery />
      <Location />
      <Footer />
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default HotelInfo;
