import React from "react";
import { FcCheckmark } from "react-icons/fc";
import { useSelector } from "react-redux";

const Notification = () => {
  const { open, info } = useSelector((state) => state.appSlice.notification);
  return (
    <div
      id="toast-bottom-right"
      className={`fixed transition-all duration-500 ${
        open ? "right-5" : "-right-80"
      } flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow  bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800`}
      role="alert"
    >
      <div className="text-sm font-normal flex justify-start items-center gap-5">
        <div className="inline-block bg-green-700 p-2 rounded-full">
          <FcCheckmark />
        </div>

        {info}
      </div>
    </div>
  );
};

export default Notification;
