import React from "react";

const reasons = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761252/liveinnstays/mussoorie/kempty_fall.jpg",
    title: "Kempty Fall",
    description:
      "These falls, which drop nearly 1,364 metres, provide breathtaking views of the valleys below.",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761475/liveinnstays/mussoorie/lal_tibba.webp",
    title: "Lal Tibba",
    description:
      "One of Mussoorie's highest viewpoints at an elevation of 2,275 metres located just outside the city.",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761632/liveinnstays/mussoorie/gun_hill.jpg",
    title: "Trek To Gun Hill Point",
    description:
      "Gun Hill Point, Mussoorie's second highest peak, is accessible via a 20-minute uphill hike.",
  },
];

const ReasonsToVisit = () => {
  return (
    <div className="px-[2.5rem] md:px-[5rem] pb-14">
      <div className="text-center text-2xl font_bold py-14">
        Reasons to Visit
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center gap-5">
        {reasons.map((reason) => (
          <div key={reason.id}>
            <div className="img_container h-64 w-full ">
              <img
                src={reason.img}
                alt="test"
                className="h-full w-full object-cover rounded-xl"
              />
            </div>
            <div className=".text-xl py-4">{reason.title}</div>
            <div className="text-sm font_light text-gray-600">
              {reason.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReasonsToVisit;
