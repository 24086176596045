import React from "react";
import { FaBed, FaBath } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import { SlSizeFullscreen } from "react-icons/sl";
import MotionWrap from "../../wrapper/MotionWrapper";
import { useNavigate } from "react-router-dom";

const propertiesData = [
  // {
  //   id: 1,
  //   img: "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/5.png",
  //   title: "Mussoorie Club",
  //   description:
  //     "Situated at the breath-taking hill station of mussoorie (The Mall Road), Hotel Mussoorie Club offers 31 spacious, well appointed rooms, with a magnificent view of doon and hills. The hotel is perfect destination for guests visiting mussoorie as it is situated on picture palace road, near the mall Rd. The hotel assures you warm hospitality and great services throughout your stay. The hotel, offers you exotic stay, food flavors & facilities like satellite led tv, king size bed, tea/coffee maker in room, complimentary wi-fi, parking facility, doctor on call and much more within serene surroundings that mesmerizing for sure.",
  //   propertyDetails: [
  //     { name: "bedroom" },
  //     { name: "bathroom" },
  //     { name: "parking" },
  //     { name: "size 322" },
  //   ],
  //   link: "/mussoorie/mussoorie-club",
  // },
  {
    id: 2,
    img: "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/18.jpg",
    title: "Hotel Mayfield",
    description:
      "Nestled amidst the enchanting hills of Mussoorie, our budget hotel is your gateway to the serene beauty and refreshing atmosphere of this picturesque hill station. Offering an affordable yet comfortable stay, we cater to travelers who seek an economical choice without compromising on the natural beauty and adventure that Mussoorie has to offer.",
    propertyDetails: [
      { name: "bedroom" },
      { name: "bathroom" },
      { name: "parking" },
      { name: "size 322" },
    ],
    link: "/mussoorie/mayfield",
  },
];

const PropertyOverview = () => {
  const navigate = useNavigate();

  return (
    <div id="property-overview" className="px-[2.5rem] md:px-[5rem]">
      <div className="text-center py-14">
        <span className="text-2xl font_bold">
          Mussoorie Properties Overview
        </span>
        <button
          onClick={() => navigate("/mussoorie")}
          className="block mx-auto mt-4 md:m-0 md:inline md:float-right bg-blue-600 px-2 py-1 text-white rounded-3xl"
        >
          View All
        </button>
      </div>

      {/* map of hotels */}
      {propertiesData.map((property) => (
        <div
          key={property.id}
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-10 last:pb-0"
        >
          {/* Image of Hotel */}
          <div className="w-full h-full">
            <img
              className="w-full h-full object-cover rounded-xl"
              src={property.img}
              alt={property.title}
            />
          </div>
          {/* Description of hotel */}
          <div className="flex flex-col justify-between">
            <div>
              <div className="text-xl pb-5">{property.title}</div>
              <div className="text-sm font_light text-gray-600 pb-4">
                {property.description}
              </div>
              {/* details of hotel */}
              <div className="flex justify-between pb-4">
                <div className="flex flex-col items-center gap-3">
                  <FaBed className="w-5 h-5" />
                  <div className="text-xs">Bedroom</div>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <FaBath className="w-5 h-5" />
                  <div className="text-xs">Bathroom</div>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <AiFillCar className="w-5 h-5" />
                  <div className="text-xs">Parking</div>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <SlSizeFullscreen className="w-5 h-5" />
                  <div className="text-xs">
                    Size 320<sup>2</sup>
                  </div>
                </div>
              </div>
            </div>

            {/* view details button */}
            <div>
              <button
                onClick={() => navigate(property.link)}
                className="bg-blue-600 px-2 py-1 text-white rounded-3xl"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MotionWrap(PropertyOverview, "app__about");
