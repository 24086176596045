import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";

function App() {
  const navItems = [
    "property-overview",
    "why choose us",
    "location",
    "contact us",
  ];

  return (
    <div className="main_container">
      <Navbar navItems={navItems} />
      <Home />
    </div>
  );
}

export default App;
