import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Accordian from "../../components/accordian/Accordian";
import { TypeAnimation } from "react-type-animation";
import { useParams } from "react-router-dom";
import LocationDescription from "../../components/location-description/LocationDescription";
import LocationProperties from "../../components/location-properties/LocationProperties";
import Footer from "../../components/footer/Footer";
import ReasonsToVisit from "../../components/reasons-to-visit/ReasonsToVisit";
import VisitingSeasons from "../../components/visiting-seasons/VisitingSeasons";
import HowToReach from "../../components/how-to-reach/HowToReach";

const HotelLocation = () => {
  const { location } = useParams();

  const slides = [
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/1.jpg",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/2.jpg",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/3.jpg",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/4.jpg",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/5.jpg",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/6.webp",
    "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698761254/liveinnstays/mussoorie/7.webp",
  ];
  const navItems = ["description", "season", "travel", "properties"];
  return (
    <div className="main_container">
      <Navbar navItems={navItems} />
      <div className="w-full m-auto relative h-1/2 md:h-[60%] lg:h-[80%]">
        <div className="w-full m-auto relative group flex flex-col h-full">
          <Accordian slides={slides} />
        </div>
        <div className="absolute flex flex-col justify-center items-center text-white bg-gradient-to-r from-black top-0 w-full h-full">
          <div className="text-2xl md:text-3xl pt-2 text-left md:text-center px-2">
            <TypeAnimation
              style={{
                whiteSpace: "pre-line",
              }}
              sequence={[`${location}`, 1000]}
              repeat={Infinity}
            />
          </div>
        </div>
      </div>
      <LocationDescription />
      <ReasonsToVisit />
      <VisitingSeasons />
      <HowToReach />
      <LocationProperties />
      <Footer />
    </div>
  );
};

export default HotelLocation;
