import React from "react";
import { BiLogoFacebook, BiLogoInstagram, BiLogoYoutube } from "react-icons/bi";

const socialLinks = [
  {
    name: "Facebook",
    icon: <BiLogoFacebook className="w-[70%] h-[70%]" />,
    link: "https://www.facebook.com",
  },
  {
    name: "Instagram",
    icon: <BiLogoInstagram className="w-[70%] h-[70%]" />,
    link: "https://www.instagram.com",
  },
  {
    name: "YouTube",
    icon: <BiLogoYoutube className="w-[70%] h-[70%]" />,
    link: "https://www.youtube.com",
  },
];

const Footer = () => {
  return (
    <div
      className="px-[2.5rem] md:px-[5rem] pt-5 bg-[#edf2f8]
    
    "
    >
      <div className="flex justify-center gap-5">
        {socialLinks.map((social) => (
          <a
            key={social.name}
            href={social.link}
            target="_blank"
            rel="noreferrer"
            className="w-8 h-8 rounded-full bg-white flex justify-center items-center"
          >
            {social.icon}
          </a>
        ))}
      </div>
      <div className="text-center text-sm font_bold pt-5 tracking-wider">
        &#169; LiveInn Hospitalities
      </div>
    </div>
  );
};

export default Footer;
