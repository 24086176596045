import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HotelInfo from "./pages/hotel-info/HotelInfo";
import HotelLocation from "./pages/hotel-location/HotelLocation";
import { Provider } from "react-redux";
import store from "./store/store";
import AppWrapper from "./wrapper/AppWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const pagesList = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     errorElement: <div>Error</div>,
//   },
//   {
//     path: "/:location",
//     errorElement: <div>Error</div>,
//     children: [
//       {
//         path: "/:location",
//         element: <HotelLocation />,
//       },
//       {
//         path: "/:location/:hotel",
//         element: <HotelInfo />,
//         errorElement: <div>Error</div>,
//       },
//     ],
//   },
// ]);

const pagesList = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper />,
    errorElement: <div>Error</div>,
    children: [
      { path: "/", element: <App />, errorElement: <div>Error</div> },
      {
        path: "/:location",
        errorElement: <div>Error</div>,
        children: [
          {
            path: "/:location",
            element: <HotelLocation />,
          },
          {
            path: "/:location/:hotel",
            element: <HotelInfo />,
            errorElement: <div>Error</div>,
          },
        ],
      },
    ],
  },
  // {
  //   path: "/:location",
  //   errorElement: <div>Error</div>,
  //   children: [
  //     {
  //       path: "/:location",
  //       element: <HotelLocation />,
  //     },
  //     {
  //       path: "/:location/:hotel",
  //       element: <HotelInfo />,
  //       errorElement: <div>Error</div>,
  //     },
  //   ],
  // },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={pagesList} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
