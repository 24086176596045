import React from "react";

const LocationDescription = () => {
  return (
    <div
      id="description"
      className="p-[2.5rem] md:p-[5rem] text-xl text-center bg-[#edf2f8]"
    >
      Mussoorie, also known as Queen of the Hills, is one of the country's most
      popular hill stations. Mussoorie is situated at a height of 2,000 metres
      above sea level on a 15-kilometre-long horseshoe ridge with the majestic
      Himalayas as a backdrop. It provides scenic views of Himalayan peaks in
      Western Garhwal from this vantage point.
    </div>
  );
};

export default LocationDescription;
