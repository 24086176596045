import React from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import { useSelector } from "react-redux";
import { email, phone } from "../../utils/constants";

const Location = () => {
  const { address, mapLink, iFrameLink } = useSelector(
    (state) => state.hotelInfoSlice.hotelInfo.location
  );

  console.log({ address, mapLink, iFrameLink });

  return (
    <div id="location" className="px-[1rem] md:px-[5rem] bg-[#edf2f8]">
      <div className="text-center text-2xl font_bold py-14">Where are we?</div>
      <div className="flex flex-col md:flex-row gap-5 justify-between items-center">
        <div className="flex flex-col gap-5">
          <div className="text-2xl font_bold">Get in touch!!</div>
          {/* location */}
          <a
            href={mapLink}
            target="_blank"
            rel="noreferrer"
            className="flex justify-start items-center gap-4 bg-[#f2f7fb] rounded-lg p-4 min-w-[290px]"
          >
            <div className="w-8 h-8">
              <img
                className="w-full h-full"
                src={
                  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598933/liveinnstays/general/location-pointer.png"
                }
                alt="mail"
              />
            </div>
            <div className="text-xs">{address}</div>
          </a>
          {/* email */}
          <a
            href={`mailto:${email}`}
            className="flex justify-start items-center gap-4 bg-[#f2f7fb] rounded-lg p-4 min-w-[290px]"
          >
            <div className="w-8 h-8">
              <img
                className="w-full h-full"
                src={
                  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598942/liveinnstays/general/gmail.png"
                }
                alt="mail"
              />
            </div>
            <div className="text-xs">{email}</div>
          </a>
          {/* phone number */}
          <a
            href={`tel:${phone}`}
            className="flex justify-start items-center gap-4 bg-[#f2f7fb] rounded-lg p-4 min-w-[290px]"
          >
            <div className="w-8 h-8">
              <img
                className="w-full h-full"
                src={
                  "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598942/liveinnstays/general/telephone.png"
                }
                alt="telephone"
              />
            </div>
            <div className="text-xs">{phone}</div>
          </a>
        </div>
        <div className="w-full h-96">
          <iframe
            title="google-maps"
            src={iFrameLink}
            className="rounded-lg w-full h-full shadow-lg"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MotionWrap(Location, "app__about");
