import React from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import "./Map.css";
import { useNavigate } from "react-router-dom";

const Map = () => {
  const navigate = useNavigate();
  return (
    <div
      id="location"
      className="px-[2.5rem] md:px-[5rem] pb-2 min-h-80 flex flex-col-reverse md:flex-row justify-center gap-5 items-center"
    >
      <div className="flex justify-center items-center h-full relative">
        <img
          src={
            "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598934/liveinnstays/general/map.png"
          }
          alt="map"
          className="h-full object-cover"
        />
        <img
          src={
            "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598933/liveinnstays/general/location-pointer.png"
          }
          alt="pointer"
          className="pointer-animation w-8 h-10 absolute"
          onClick={() => navigate("/mussoorie")}
        />
      </div>
      <div className="text-center text-2xl font_bold py-14">
        Where are we located?
      </div>
    </div>
  );
};

export default MotionWrap(Map, "app__about");
