export const phone = "+919412201924";
export const email = "liveinnhospitalities@gmail.com";
export const allMussoorieHotels = {
  // "mussoorie-club": {
  //   name: "Mussoorie Club",
  //   images: [
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/1.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/2.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/3.jpg",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/4.jpg",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/5.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/6.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/7.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/8.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/9.jpg",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/10.jpg",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/11.png",
  //     "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/12.jpg",
  //   ],
  //   "about us": {
  //     image:
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698597403/liveinnstays/mussoorie-club/4.png",
  //     alt: "mussoorie-club",
  //     title: "Make your Golden Memory with Us!",
  //     description:
  //       "Warm and welcoming hospitality awaits you at Mussoorie club. The Mussoorie club have been on a continuous journey to delight our guests by providing unparalleled luxury and services wrapped in the graciousness of Indian hospitality. The Mussoorie Club offers a unique opportunity of experienced hospitality in the lap of nature for all to feel like home. If you are looking for serenity, this is the place to be. An oasis of calmness, away from the daily hustle and bustle of life, this is one of the best hotels in Mussoorie. We invite you to feel this distinction, taste and enjoy it with all your senses.",
  //   },
  //   aminities: {
  //     "free wifi":
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698836871/liveinnstays/general/wifi.png",
  //     "room service":
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837048/liveinnstays/general/room-service.png",
  //     bathroom:
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837177/liveinnstays/general/bathroom.png",
  //     "free parking":
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837335/liveinnstays/general/parking.png",
  //     "cable TV":
  //       "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837491/liveinnstays/general/tv.png",
  //   },
  //   location: {
  //     address:
  //       "Tilak Road, Near Picture Palace, The Mall Road, Mussorie Uttrakhand, 248179",
  //     mapLink: "https://maps.app.goo.gl/CJeUPnffNHGxDBoq7",
  //     iFrameLink:
  //       "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13757.036560634828!2d78.062983056956!3d30.457097345490936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908d0c45ab59e49%3A0x12a33ff254c49923!2sHotel%20Mussoorie%20Club%20(By%20Applewood%20hotels%20and%20resorts)!5e0!3m2!1sen!2sin!4v1698590461879!5m2!1sen!2sin",
  //   },
  // },
  mayfield: {
    name: "Hotel Mayfield",
    images: [
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/18.jpg",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/17.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698843206/liveinnstays/mayfield/16.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/15.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/14.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698843201/liveinnstays/mayfield/12.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/11.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/10.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/11.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/10.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/9.avif",
      "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/8.avif",
    ],
    "about us": {
      image:
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698842115/liveinnstays/mayfield/18.jpg",
      alt: "hotel mayfield",
      title: "Make your Golden Memory with Us!",
      description:
        "Warm and welcoming hospitality awaits you at Mussoorie club. The Mussoorie club have been on a continuous journey to delight our guests by providing unparalleled luxury and services wrapped in the graciousness of Indian hospitality. The Mussoorie Club offers a unique opportunity of experienced hospitality in the lap of nature for all to feel like home. If you are looking for serenity, this is the place to be. An oasis of calmness, away from the daily hustle and bustle of life, this is one of the best hotels in Mussoorie. We invite you to feel this distinction, taste and enjoy it with all your senses.",
    },
    aminities: {
      "free wifi":
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698836871/liveinnstays/general/wifi.png",
      "room service":
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837048/liveinnstays/general/room-service.png",
      bathroom:
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837177/liveinnstays/general/bathroom.png",
      "free parking":
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837335/liveinnstays/general/parking.png",
      "cable TV":
        "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698837491/liveinnstays/general/tv.png",
    },
    location: {
      address:
        "Mall Rd, near Picture Palace, The Mall Road, Mussoorie, Uttarakhand 248179",
      mapLink: "https://maps.app.goo.gl/1i9P3EiAuVHQHurNA",
      iFrameLink:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.327625346541!2d78.07884047556954!3d30.45515707471937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908d0c416fd928b%3A0x4f93f7e56db9f1dc!2sHotel%20Mayfield!5e0!3m2!1sen!2sin!4v1698845071201!5m2!1sen!2sin",
    },
  },
};
