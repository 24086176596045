import React from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import { useSelector } from "react-redux";

const Aminities = () => {
  const aminitiesData = useSelector(
    (state) => state.hotelInfoSlice.hotelInfo.aminities
  );
  return (
    <div id="aminities" className="px-[2.5rem] md:px-[5rem] pb-10">
      <div className="text-center text-2xl font_bold py-14">Why choose us?</div>
      {/* why choose us options */}
      <div className="flex flex-wrap justify-evenly gap-4">
        {Object.entries(aminitiesData).map((aminity, i) => (
          <div key={i} className="flex flex-col items-center">
            <div className="h-28 w-28 rounded-full bg-[#edf2f8] flex justify-center items-center">
              <img src={aminity[1]} alt={aminity[0]} className="h-14 w-14" />
            </div>
            <div className="text-sm font_semibold capitalize">{aminity[0]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MotionWrap(Aminities, "app__about");
