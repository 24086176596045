import { configureStore } from "@reduxjs/toolkit";
import hotelInfoSlice from "./slices/hotelInfoSlice";
import appSlice from "./slices/appSlice";

const store = configureStore({
  reducer: {
    hotelInfoSlice: hotelInfoSlice,
    appSlice: appSlice,
  },
});

export default store;
