import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelInfo: {},
};

const hotelInfoSlice = createSlice({
  name: "hotelInfoSlice",
  initialState,
  reducers: {
    removeInfo: (state, action) => {
      state.hotelInfo = {};
    },
    addInfo: (state, action) => {
      state.hotelInfo = action.payload;
    },
  },
});

export const { removeInfo, addInfo } = hotelInfoSlice.actions;

export default hotelInfoSlice.reducer;
