import React, { useRef, useState } from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";
import {
  disableNotification,
  enableNotification,
} from "../../store/slices/appSlice";
import { email, phone } from "../../utils/constants";

const ContactUs = () => {
  const dispatch = useDispatch();
  const form = useRef();
  const [sending, setSending] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log(form.current);
    setSending(true);
    try {
      const results = await emailjs.sendForm(
        "service_ecxx3zd",
        "template_eu037xp",
        form.current,
        "TyPoJqfjzFPgQkg24"
      );
      console.log(results);
      dispatch(enableNotification());
      setTimeout(() => {
        dispatch(disableNotification());
      }, 3000);
      e.target.reset();
    } catch (error) {
      console.log(error.text);
    } finally {
      setSending(false);
    }
  };

  return (
    <div
      id="contact us"
      className="px-[2.5rem] md:px-[5rem] pb-10 bg-[#edf2f8]"
    >
      <div className="text-center text-2xl font_bold py-14">Contact Us</div>
      <div className="flex flex-col sm:flex-row items-center sm:justify-center gap-5">
        {/* Email */}
        <a
          href={`mailto:${email}`}
          className="flex justify-start items-center gap-4 bg-[#fef4f5] rounded-lg p-4 min-w-[290px]"
        >
          <div className="w-8 h-8">
            <img
              className="w-full h-full"
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598933/liveinnstays/general/gmail.png"
              }
              alt="mail"
            />
          </div>
          <div className="text-xs">{email}</div>
        </a>
        {/* Phone Number */}
        <a
          href={`tel:${phone}`}
          className="flex justify-start items-center gap-4 bg-[#f2f7fb] rounded-lg p-4 min-w-[290px]"
        >
          <div className="w-8 h-8">
            <img
              className="w-full h-full"
              src={
                "https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698598942/liveinnstays/general/telephone.png"
              }
              alt="telephone"
            />
          </div>
          <div className="text-xs">{phone}</div>
        </a>
      </div>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col w-full md:w-[60%] m-auto gap-5 pt-5 text-sm"
      >
        <input
          type="text"
          placeholder="name"
          name="from_name"
          className="p-4 rounded-lg"
        />
        <input
          type="text"
          placeholder="email"
          name="user_email"
          className="p-4 rounded-lg"
        />
        <textarea
          placeholder="ask us anything, we are here for you"
          name="message"
          className="p-4 rounded-lg"
        ></textarea>
        <button
          type="submit"
          value="Send"
          className="w-full md:w-[20%] m-auto bg-blue-600 text-white p-4 rounded-xl"
        >
          {sending ? "Sending..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default MotionWrap(ContactUs, "app__about");
