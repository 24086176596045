import React from "react";

const VisitingSeasons = () => {
  return (
    <div id="season" className="px-[2.5rem] md:px-[5rem] pb-14">
      <div className="text-center text-2xl font_bold py-14">
        Best time to visit
      </div>
      <div className="flex justify-center items-center gap-12">
        <div>
          <div className="h-20 w-20 mb-2">
            <img
              className="h-full w-full animation"
              src="https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698762541/liveinnstays/general/sun.png"
              alt="sun"
            />
          </div>
          <div className="text-center">
            12°C - 23°C <br /> Apr-Jun
          </div>
        </div>
        <div>
          <div className="h-20 w-20 mb-2">
            <img
              className="h-full w-full animation"
              src="https://res.cloudinary.com/dgxwmhtoo/image/upload/v1698762822/liveinnstays/general/winter.png"
              alt="winter"
            />
          </div>
          <div className="text-center">
            3°C - 19°C <br /> Oct-Feb
          </div>
        </div>
      </div>
      <div className="py-10">
        <span className="font_bold">April-June:</span> Away from the scorching
        heat of the Indian summers, this is the perfect time to visit Mussoorie
        and enjoy a relaxing vacation in the lap of these gorgeous mountains.
      </div>
      <div>
        <span className="font_bold">October-February:</span> For people who
        enjoy snowfalls, this is the ideal time to visit Mussoorie. With
        temperature going down to zero degrees, one can experience dazzling
        white snow spread over the little town of Mussoorie.
      </div>
    </div>
  );
};

export default VisitingSeasons;
