import React from "react";
import MotionWrap from "../../wrapper/MotionWrapper";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const { image, alt, title, description } = useSelector(
    (state) => state.hotelInfoSlice.hotelInfo["about us"]
  );

  return (
    <div id="about us" className="px-[2.5rem] md:px-[5rem]">
      <div className="text-center text-2xl font_bold py-14">About Us</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-10 last:pb-0">
        {/* Image of Hotel */}
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover rounded-xl"
            src={image}
            alt={alt}
          />
        </div>
        {/* Description of hotel */}
        <div className="flex flex-col justify-center items-center">
          <div>
            <div className="text-2xl pb-5">{title}</div>
            <div className=" font_light text-gray-600 pb-4">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
// export default AboutUs;
export default MotionWrap(AboutUs, "app__about");
